<script>
  export default {
    data() {
      return {
        token: {
          digit1: "",
          digit2: "",
          digit3: "",
          digit4: "",
          digit5: "",
          digit6: "",
        },
      };
    },

    methods: {
      onKeyUp(event) {
        const input = event.target;
        const inputRef = input.name;
        const value = event.key;
        const numericValue = parseInt(value);

        if (value === "Backspace") {
          this.focusPrevInput(inputRef);
        }

        if (isNaN(numericValue)) {
          this.cleanInput(inputRef);
        } else {
          this.token[inputRef] = numericValue;
          this.focusNextInput(inputRef);
        }
      },

      focusPrevInput(inputRef) {
        const prevInput = document.querySelector(`.${inputRef}`).previousSibling;
        if (prevInput) {
          prevInput.focus();
        }
      },

      cleanInput(inputRef) {
        document.querySelector(`.${inputRef}`).value = "";
      },

      focusNextInput(inputRef) {
        const nextInput = document.querySelector(`.${inputRef}`).nextSibling;

        if (!nextInput) {
          this.emitEvent();
        } else {
          nextInput.focus();
        }
      },

      focusFirstInput() {
        document.querySelector(".digit1").focus();
      },

      returnVerificationCode() {
        const verificationCode = this.getInputVerificationCode();
        const isValidCode = this.isValidVerificationCode(verificationCode);
        let code = 0;

        if (isValidCode) {
          code = parseInt(verificationCode);
        }

        this.cleanTOTPCode();
        this.focusFirstInput();
        return code;
      },

      getInputVerificationCode() {
        return Object.values(this.token).join("");
      },

      isValidVerificationCode(vericationCode) {
        return vericationCode.length === 6;
      },

      cleanTOTPCode() {
        for (const item in this.token) {
          this.token[item] = "";
        }
      },

      emitEvent() {
        const verificationCode = this.returnVerificationCode();
        this.$emit("getToken", verificationCode);
      },
    },

    mounted() {
      this.focusFirstInput();
    },
  };
</script>

<template>
  <div class="tokenInput">
    <input
      v-for="(item, key) in token"
      type="text"
      inputmode="numeric"
      @keyup="onKeyUp($event)"
      :ref="`${key}`"
      :name="key"
      :class="key"
      maxlength="1"
      v-model="token[key]"
      autocomplete="off"
      :key="key"
    />
  </div>
</template>

<style lang="scss">
  .tokenInput {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    gap: 10px;

    [class*="digit"] {
      width: 40px;
      height: 40px;
      padding: 10px;
      text-align: center;
      font-weight: bold;
      border: 0;
      border-radius: 8px;
      background: $porcelain;
      color: $lipstick;
      transition: all ease-in 50ms;

      &:focus {
        border: 2px solid $lipstick;
        transition: all ease-in 50ms;
      }
    }
  }
</style>
