<script>
  import { mapGetters } from "vuex";
  import TokenInput from "../auth/TokenInput.vue";
  import sessionsApi from "../../common/api/sessionsAxios";

  export default {
    components: {
      TokenInput,
    },
    data() {
      return {
        method: this.$route.query.method,
        verificationCode: null,
        contactMessages: {
          phone: "Ingresa el código de 6 dígitos que se acaba de enviar por mensaje de texto tu telefono:",
          email: "Ingresa el código de 6 dígitos que se acaba de enviar por email a tu correo:",
          totp: "Ingresa el código de 6 dígitos que se acaba de generar en Google Authenticator:",
        },
      };
    },

    methods: {
      ...mapGetters("user", { getUserId: "getUserId", getQueryUser: "getQueryUser" }),
      async getToken(verificationCode) {
        const requestBody = {
          verificationCode: verificationCode.toString(),
          userId: this.getUserId(),
          method: this.method,
        };

        let {
          data: { error, isValid },
        } = await sessionsApi.post("/auth/verifyCode", requestBody);

        if (error || !isValid) {
          this.$toast.error("El código es inválido");
        } else {
          this.$router.push({ name: "newPassword" });
        }
      },

      getInputToken() {
        this.$refs.tokenInput.returnVerificationCode();
      },

      async sendMessage() {
        const requestBody = {
          user: this.getQueryUser(),
          authMethod: this.method,
        };
        await sessionsApi.post("/auth/sendCode", requestBody);
      },
    },

    async mounted() {
      await this.sendMessage();
    },
  };
</script>

<template>
  <div class="restorePassMethod">
    <form class="restorePassMethod__form" @submit.prevent>
      <div class="restorePassMethod__inputGroup">
        <p class="restorePassMethod__desc">{{ this.contactMessages[this.method] }}</p>
        <TokenInput @getToken="getToken" ref="tokenInput" />
        <button class="restorePassMethod__submit" @click="getInputToken">Enviar</button>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
  .restorePassMethod {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__form {
      width: 100%;
      max-width: 377px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 25px;
    }

    &__inputGroup {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    &__desc {
      width: 80%;
      font-family: $newFont_regular;
      font-size: 15px;
      text-align: justify;
      margin-bottom: 10px;
    }

    &__submit {
      width: 100%;
      max-width: 300px;
      border: none;
      border-radius: 8px;
      background-color: $primary-color;
      height: 40px;
      color: $white;
      transition: all ease-in 0.3s;
      font-family: $newFont_semiBold;
      margin-top: 20px;

      &:hover {
        opacity: 0.5;
      }
    }

    @include tabletWidth() {
      .restorePassMethod {
        &__desc {
          width: 70%;
        }
      }
    }

    @include screen_tv() {
      .restorePassMethod {
        &__desc {
          width: 90%;
        }
      }
    }
  }
</style>
